import { useScrollPosition } from '../hooks/useScrollPosition'
import { CaretUp } from '@phosphor-icons/react'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import { animateScroll as scroll } from 'react-scroll'

export function ButtonUp() {
  const scrollPosition = useScrollPosition()

  return (
    <motion.div
      initial={{ y: 0, opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ y: 0, opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="hidden md:block"
    >
      <button
        className={clsx(
          scrollPosition > 400 ? 'flex items-center justify-center' : 'hidden',
          'fixed bottom-10 right-10 z-50 h-10 w-10 cursor-pointer rounded-full border border-primary-500 bg-background-color transition duration-300 ease-in-out',
        )}
        onClick={scroll.scrollToTop}
      >
        <CaretUp size={24} color="#333620" />
      </button>
    </motion.div>
  )
}
