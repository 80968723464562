import { FAQQuestion } from '../components/FAQQuestion'
import Balancer from 'react-wrap-balancer'

const FAQComunidade = () => {
  return (
    <section className="mb-24 px-6 pb-6 md:pt-0 lg:mx-28">
      {/* <div className="w-full flex justify-center left-0 mb-2 -top-[19px]">
      <h1>Atendimento</h1>
    </div> */}
      {/* <div className="absolute -top-[19px] left-0 flex w-full justify-start pl-[10%]">
        <h1>FAQ</h1>
      </div> */}

      <div className="flex flex-col justify-between md:flex-row">
        <div className="mb-12 w-full md:mb-0 md:w-1/2">
          <div className="sticky top-24 w-full md:w-[70%]">
            <h1 className="text-2xl pb-4 md:pb-8 uppercase md:text-3xl">
              <Balancer>Tire suas dúvidas sobre a comunidade</Balancer>
            </h1>

            <p>
              Ainda com dúvidas?{' '}
              <a
                href="https://api.whatsapp.com/send?phone=5547999785847&text=Ol%C3%A1!%20Encontrei%20você%20pelo%20site,%20gostaria%20de%20tirar%20uma%20dúvida"
                target="_blank"
                className="font-medium text-primary-600 underline"
                rel="noreferrer"
              >
                Entre em contato com a nutri pelo WhatsApp
              </a>
            </p>
          </div>
        </div>

        <div className="flex w-full flex-col gap-y-16 md:w-1/2">
          <FAQQuestion
            question="Como funciona?"
            answer={
              <>

                  <span>
                    <Balancer>
                    Ao pagar a anuidade, você terá acesso a comunidade NUTRITUDES e acesso a nutri via Zoom por 1 ano.
                    </Balancer>
                  </span>

              </>
            }
          />

          <FAQQuestion
            question="Tenho uma garantia?"
            answer={
              <>

                  <span>
                    <Balancer>
                    Se por qualquer razão achar que o investimento não valeu a pena, é só entrar em contato com a gente e receberá de volta 100% do valor pago sem a menor burocracia.
                    </Balancer>
                  </span>

              </>
            }
          />

          <FAQQuestion
            question="A garantia vale para a mentoria em grupo?"
            answer={
              <>

                  <span>
                    <Balancer>
                      Sim, se por qualquer razão achar que o investimento não valeu a pena, é só entrar em contato com a gente e receberá de volta 100% do valor pago sem a menor burocracia.
                    </Balancer>
                  </span>

              </>
            }
          />

          <FAQQuestion
            question="Preciso conhecer de tecnologia para usar o PRODUTO?"
            answer={
              <>

                  <span>
                    <Balancer>
                    Ao efetuar o seu pedido, você receberá um e-mail explicando como acessar os canais (Nutror e Telegram). Tudo muito simples e fácil!
                    </Balancer>
                  </span>

              </>
            }
          />

          <FAQQuestion
            question="Não tenho nenhuma doença específica, esse produto me ajuda?"
            answer={
              <>

                  <span>
                    <Balancer>
                    Se você não apresenta nenhuma doença, sempre é bom prevenir. Após os 40 anos nosso corpo já começa a dar sinais de envelhecimento. Se quiser uma longevidade saudável, prevenção é o caminho mais fácil e barato. 
                    </Balancer>
                  </span>

              </>
            }
          />
        </div>
      </div>
    </section>
  )
}

export default FAQComunidade