import Button from "./Button";
import { ReactNode } from "react";

interface BeyondTreatmentOptionProps {
  title: string
  subtitle: string
  description: ReactNode
  sourceImage: string
  widthImage: number
  buttonText?: string
  buttonLink?: string
  bgImage: string
}

export function BeyondTreatmentOption({ ...props }: BeyondTreatmentOptionProps) {
  return (
    <>
      <div className={`flex relative flex-row ${props.subtitle === 'PRI60DIAS' ? 'mb-0 md:mb-16' : 'mb-16'}  pl-12 lg:pl-0`}>
        <div className="hidden w-1/3 md:flex justify-center">
          <div className={`z-10 h-56 ${props.subtitle === 'PRI60DIAS' ? 'mb-0 md:mb-16' : 'mb-20'}`}>
            <img className="z-10" src={props.sourceImage} alt="" width={props.widthImage} placeholder="blur"/>
          </div>


          {
            props.subtitle === 'PRI60DIAS' ? 
            <div className="absolute left-0 -bottom-4 w-full lg:w-1/2 z-0 bg-gradient-to-r from-[#696B5C] h-12 flex flex-row items-center mb-2 px-6 lg:pl-28 gap-x-2">
              <h4 className="text-primary-600 text-xl uppercase">Neste programa vou te mostrar:</h4>
            </div>
            :
            <></>
          }
        </div>

        {/* DESKTOP */}
        <div className="hidden md:flex flex-col px-12 w-2/3">
          <span className="uppercase text-2xl">{props.title}</span>
          <span className="uppercase text-lg text-brown-500">{props.subtitle}</span>
          <div className="text-base font-light my-6 pr-0 md:pr-36">{props.description}</div>

          {
            props.buttonText !== undefined ?
            <div>
              <a href={props.buttonLink} target="_blank" rel="noreferrer">
                <Button>{props.buttonText}</Button>
              </a>
            </div>
            :
            <></>
          }

        </div>

        {/* MOBILE */}
        <div className="md:hidden flex flex-col pr-12 w-full">
          <div className="flex flex-row gap-x-2">
            <div className="z-10">
              <img className="z-10" src={props.sourceImage} alt="" width={props.title === 'Grupo VIP' ? 100 : 200} height={props.title === 'Grupo VIP' ? 100 : 200} loading="lazy"/>
            </div>

            <div className="flex flex-col">
              <span className="uppercase text-2xl">{props.title}</span>
              <span className="uppercase text-lg text-brown-500">{props.subtitle}</span>
            </div>
          </div>

          <div className="text-base font-light my-6 pr-0 md:pr-36">{props.description}</div>

          {
            props.buttonText !== undefined ?
            <div>
              <a href={props.buttonLink} target="_blank" rel="noreferrer">
                <Button>{props.buttonText}</Button>
              </a>
            </div>
            :
            <></>
          }

        </div>

        <div className="hidden md:flex absolute right-0">
          <img src={props.bgImage} alt="" width={250} />
        </div>
      </div>



    </>
  )
}