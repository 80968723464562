'use client'

import clsx from "clsx"

import KiwiImage from '../images/kiwi.png'
import BiobalanceIcon from '../images/BiobalanceIcon.svg'
import Button from "../components/Button"
import { Article, Browsers, ClockClockwise, Dna, ForkKnife, InstagramLogo, Laptop, Leaf, Question, Strategy, Trophy, User, WhatsappLogo } from "@phosphor-icons/react"
import ApplesImage from "../images/apples.png"

const Service = () => {
  return (
    <section id="acompanhamento" className="mb-16 lg:mb-32">
    <div className={clsx("flex flex-1 flex-col justify-center px-6 lg:px-28")}>
      {/* <div className=""> */}
      <h1 className="text-3xl md:text-5xl font-alt mb-8">Acompanhamento</h1>

      <div className="flex flex-col items-center mb-8">
        <div className="flex flex-col gap-y-8 my-8">
          <div className="flex flex-row items-center gap-x-2">
            <span><ForkKnife size={24} weight="duotone"/></span>
            <h2 className="flex flex-row gap-2 font-normal">Ofereço um Tratamento Nutricional Funcional Integrativo</h2>
          </div>

          <div className="flex flex-row items-center gap-x-2">
            <span><Strategy size={24} weight="duotone"/></span>
            <h2 className="flex flex-row gap-2 font-normal">Estratégia Nutricional de Acordo com as SUAS Necessidades</h2>
          </div>

          <div className="flex flex-row items-center gap-x-2">
            <span><Laptop size={24} weight="duotone"/></span>
          <h2 className="flex flex-row gap-2 font-normal">Atendimento 100% Online</h2>
          </div>
        </div>
      </div>

      {/* <div className="hidden md:flex flex-row justify-between">
        <div>
          <span className="text-4xl">8 </span> <span className="text-2xl font-light uppercase">Semanas</span>
        </div>

        <div>
          <span className="text-4xl">12 </span> <span className="text-2xl font-light uppercase">Semanas</span>
        </div>
      </div> */}
    </div>

    <div className="w-full flex flex-col md:flex-row justify-between">
        <div className='w-full md:w-[40%] flex flex-col mt-2'>
            {/* <div className="flex items-end justify-start md:hidden gap-x-2 ml-8">
              <span className="text-4xl">8 </span> <span className="text-2xl font-light uppercase">Semanas</span>
            </div> */}

            <div className="bg-primary-500 h-12 md:rounded-tr-lg flex items-center justify-end pr-8 mb-8">
              <h3 className="text-background-color">Acompanhamento Padrão</h3>
            </div>


            <div className="flex flex-col pb-6">
              <div className="bg-gradient-to-r from-[#aeaead] h-12 flex flex-row items-center mb-2 px-6 lg:px-28 gap-x-2">
                <Browsers size={24} weight="light"/><h4 className="text-primary-600 text-base">Acesso ao WebDiet</h4>
              </div>
              <p className="px-6 lg:pl-28">
                Plataforma onde será reunida todas as suas informações. Além disso, você poderá obter descontos em lojas, farmácias, sites e laboratórios de exame.
              </p>

              <p className="px-6 lg:pl-28">
                <br />
                Com o WebDiet você terá acesso ao MoveHealth, uma plataforma com aulas de atividade física, terapias guiadas, yoga e muito mais.
              </p>
            </div>

            <div className="flex flex-col pb-6">
              <div className="bg-gradient-to-r from-[#aeaead] h-12 flex flex-row items-center mb-2 px-6 lg:px-28 gap-x-2">
                <User size={24} weight="light"/><h4 className="text-primary-600 text-base">Atendimento de 90min</h4>
              </div>
              <p className="px-6 lg:pl-28">
                No acompanhamento padrão você tem direito a uma consulta de 1 hora e meia, nela irei entender suas dores para atender as suas necessidades da melhor forma possível.
              </p>
            </div>

            <div className="flex flex-col pb-6">
              <div className="bg-gradient-to-r from-[#aeaead] h-12 flex flex-row items-center mb-2 px-6 lg:px-28 gap-x-2">
                <Question size={24} weight="light"/><h4 className="text-primary-600 text-base">Sessão tira dúvidas de 30min</h4>
              </div>
              <p className="px-6 lg:pl-28">
                Dentro do prazo de 4 semanas você poderá tirar suas dúvidas via video conferência de 30 minutos através do Zoom ou WhatsApp.
              </p>
            </div>

            <div className="flex flex-col pb-6">
              <div className="bg-gradient-to-r from-[#aeaead] h-12 flex flex-row items-center mb-2 px-6 lg:px-28 gap-x-2">
                <InstagramLogo size={24} weight="light"/><h4 className="text-primary-600 text-base">Acesso ao perfil exclusivo</h4>
              </div>
              <p className="px-6 lg:pl-28">
                Você fará parte de um grupo seleto que terá acesso a posts exclusivos em um perfil privado do Instagram.
              </p>
            </div>

            <div className="flex flex-col pb-6">
              <div className="bg-gradient-to-r from-[#aeaead] h-12 flex flex-row items-center mb-2 px-6 lg:px-28 gap-x-2">
                <Article size={24} weight="light"/><h4 className="text-primary-600 text-base">Materiais exclusivos</h4>
              </div>
              <p className="px-6 lg:pl-28">
              E-books de receitas, de tratamentos, de chás. Você terá acesso a um infográfico com passo a passo para ajudá-lo(a) a alcançar seus objetivos.
              </p>
            </div>
        </div>

        <div className='w-full md:w-[40%] flex flex-col mt-2'>
            {/* <div className="flex items-end justify-end md:hidden gap-x-2 mr-8">
              <span className="text-4xl">12 </span> <span className="text-2xl font-light uppercase">Semanas</span>
            </div> */}

            <div className="bg-primary-500 h-12 md:rounded-tl-lg flex items-center pl-6 mb-8">
              <h3 className="text-background-color">Acompanhamento Biobalance</h3>
            </div>

            <div className="bg-gradient-to-l text-base from-[#aeaead] h-12 flex items-center pl-6 sm:pl-6 mb-8 pr-6 lg:pr-28">
              <h4 className="text-primary-600">Tudo o que o Acompanhamento Padrão Oferece +</h4>
            </div>

            <div className="w-full flex flex-row pl-2 sm:pl-6">
              <img src={BiobalanceIcon} alt="" width={110} />

              <div className="w-full flex-col text-base bg-gradient-to-l from-[#a78d9d] flex items-start justify-center gap-y-4 pl-6 pr-6 lg:pr-28">
                <p>Mentoria via WhatsApp diária</p>
                <p>+6 atendimentos de 1 hora cada</p>
                <p>Bônus personalizados</p>
              </div>
            </div>

            <div className="flex flex-col pb-6">
              <div className="mt-8 bg-gradient-to-l from-[#a78d9d] h-12 flex flex-row items-center mb-2 pl-6 sm:pl-6 pr-6 lg:pr-28 gap-x-2">
                  <Trophy size={24} weight="light"/><h4 className="text-primary-600 text-base">Gameficação</h4>
              </div>

              <p className="pl-6 sm:pl-6 pr-6 lg:pr-28 ">
                No acompanhamento Biobalance você terá um passo a passo gameficado para visualizar o caminho para alcançar seus objetivos.
              </p>
            </div>

            <div className="bg-primary-500 h-12 md:rounded-tl-lg flex items-center pl-6 mb-8">
              <h3 className="text-background-color md:text-base">O método Biobalance possui 3 etapas</h3>
            </div>

          <div className="flex flex-row relative md:text-sm lg:text-base">
            <div className="w-full px-6 xl:pr-0 xl:w-9/12 flex flex-col gap-y-7">
              <span className="border-2 border-primary-500 py-3 px-7 md:px-4 rounded-[4px]">
                Etapa 1 | Organização e Detox
              </span>

              <span className="border-2 border-primary-500 py-3 px-7 md:px-4 rounded-[4px]">
                Etapa 2 | Aceleração de Resultados
              </span>

              <span className="border-2 border-primary-500 py-3 px-7 md:px-4 rounded-[4px]">
                Etapa 3 | Manutenção dos Resultados
              </span>
            </div>

            <div className="hidden xl:flex absolute right-6 -top-16 w-[180px]">
              <img src={ApplesImage} alt="Três maçãs, uma para cada etapa" placeholder="blur" />
            </div>
          </div>

        </div>
      </div>

    <div className="w-full bg-primary-500 h-64 md:h-[148px] flex flex-row mt-32 md:mt-44 items-center px-6 lg:px-28 mb-16">
      <div className="flex w-[200px]">
        <img src={KiwiImage} alt="Kiwi" placeholder="blur" />
      </div>

      <div className="w-full flex flex-col items-center justify-between md:flex-row gap-y-8">
        <p className="hidden md:flex text-center text-lg md:px-8 md:text-xl font-light text-background-color">
          Para mais detalhes sobre os acompanhamentos e qual o valor do investimento a ser realizado, clique no botão ao lado
        </p>

        <p className="md:hidden text-center text-lg md:px-8 md:text-xl font-light text-background-color">
          Para mais detalhes sobre os acompanhamentos e qual o valor do investimento a ser realizado, clique no botão abaixo
        </p>

        <div>
          <Button backgroundcolor={"brownkiwi-500"}>
            <a href="https://api.whatsapp.com/send?phone=5547999785847&text=Ol%C3%A1!%20Encontrei%20voc%C3%AA%20pelo%20site" target="_blank" rel="noreferrer">
              Falar com a Nutri  
            </a> 
          </Button>
        </div>

      </div>

    </div>
    </section>
  )
}
export default Service