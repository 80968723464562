import { Link } from 'react-scroll'

import LogoImagePrimary from '../images/logo.svg'
import LogoImageSecondary from '../images/logoWhite.svg'
import { useEffect, useState } from 'react'
import { clsx } from 'clsx'
import { useFood } from '../context/foods.context'

export function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [isScrollDown, setIsScrollDown] = useState(false)
  const {food} = useFood()

  function scrollFunction() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      setIsScrollDown(true)
    } else {
      setIsScrollDown(false)
    }
  }

  useEffect(() => {
    window.onscroll = function () {
      scrollFunction()
    }

    return () => {
      window.onscroll = null;
    };
  }, [])

  return (
    <header
      className={clsx(`fixed left-0 z-50 flex w-full transform flex-row items-center justify-between pb-5 pt-5 transition-all duration-150 ease-in-out px-8 lg:px-32  ${
        isScrollDown
          ? 'pt-5 text-sm shadow-sm'
          : 'pt-10'
      }`, food.food === 'none' ? 'bg-background-color' : isScrollDown ? 'bg-background-color' : 'bg-transparent')}
    >
      {/* <header className="bg-beige-100 l-0 fixed flex w-fit flex-row items-center justify-between pt-10"> */}
      <div className={`${isScrollDown ? 'h-8' : 'h-full'}`}>
        <a href="https://elieteborges.com.br">
          <img
            src={food.food === "none" ? LogoImagePrimary : isScrollDown ? LogoImagePrimary : LogoImageSecondary}
            alt="Nutricionista Eliete Borges"
            className={`${isScrollDown ? 'h-[36px]' : 'h-[56px]'}`}
          />
        </a>
      </div>

      <nav>
        <section className="MOBILE-MENU flex lg:hidden">
          <div
            className="HAMBURGER-ICON cursor-pointer space-y-2"
            onClick={() => setIsNavOpen((prev) => !prev)} // toggle isNavOpen state on click
          >
            <span className={`block h-0.5 w-6 ${food.food === 'none' ? 'bg-primary-500' : isScrollDown ? 'bg-primary-500' : 'bg-white'}`}></span>
            <span className={`block h-0.5 w-6 ${food.food === 'none' ? 'bg-primary-500' : isScrollDown ? 'bg-primary-500' : 'bg-white'}`}></span>
            <span className={`block h-0.5 w-6 ${food.food === 'none' ? 'bg-primary-500' : isScrollDown ? 'bg-primary-500' : 'bg-white'}`}></span>
          </div>

          <div className={isNavOpen ? 'showMenuNav' : 'hideMenuNav'}>
            <div
              className="CROSS-ICON absolute right-0 top-0 px-8 py-8"
              onClick={() => setIsNavOpen(false)} // change isNavOpen state to false to close the menu
            >
              <svg
                className="h-8 w-8 cursor-pointer text-primary-500"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <ul className="MENU-LINK-MOBILE-OPEN flex min-h-[250px] flex-col items-center justify-between text-primary-600">
              <li className="my-8 border-b border-primary-500 uppercase">
                <Link
                  activeClass="active"
                  to="acompanhamento"
                  spy={true}
                  offset={-80}
                  smooth={true}
                  duration={800}
                  className="hover:opacity-50 transition-opacity"
                  href="/#acompanhamento"
                  onClick={() => setIsNavOpen(false)}
                >
                  Acompanhamento
                </Link>
              </li>
              <li className="my-8 border-b border-primary-500 uppercase">
                <Link
                  activeClass="active"
                  to="sobre"
                  spy={true}
                  offset={-80}
                  smooth={true}
                  duration={800}
                  className="hover:opacity-50 transition-opacity"
                  href="/#sobre"
                  onClick={() => setIsNavOpen(false)}
                >
                  Sobre a Nutri
                </Link>
              </li>
              <li className="my-8 border-b border-primary-500 uppercase">
                <Link
                  activeClass="active"
                  to="quero+"
                  spy={true}
                  offset={-80}
                  smooth={true}
                  duration={800}
                  className="hover:opacity-50 transition-opacity"
                  href="/#quero+"
                  onClick={() => setIsNavOpen(false)}
                >
                  Quero +
                </Link>
              </li>
              <li className="my-8 border-b border-primary-500 uppercase">
                <Link
                  activeClass="active"
                  to="duvidas"
                  spy={true}
                  offset={-80}
                  smooth={true}
                  duration={800}
                  className="hover:opacity-50 transition-opacity"
                  href="/#duvidas"
                  onClick={() => setIsNavOpen(false)}
                >
                  Dúvidas
                </Link>
              </li>
            </ul>
          </div>
        </section>

        <section className={clsx("text-sm hidden lg:flex",food.food === 'none' ? "text-primary-500" : ` text-white ${
        isScrollDown
          ? '!text-primary-500'
          : ''
      }` )}>
          <ul className="flex">
            <li className="px-4">
              <Link
                activeClass="active"
                to="acompanhamento"
                spy={true}
                offset={-80}
                smooth={true}
                duration={800}
                className="hover:opacity-50 transition-opacity"
                href="/#acompanhamento"
              >
                Acompanhamento
              </Link>
            </li>

            <li className="px-4">
              <Link
                activeClass="active"
                to="sobre"
                spy={true}
                offset={-40}
                smooth={true}
                duration={900}
                className="hover:opacity-50 transition-opacity"
                href="/#sobre"
              >
                Sobre a Nutri
              </Link>
            </li>

            <li className="px-4">
              <Link
                activeClass="active"
                to="quero+"
                spy={true}
                offset={-90}
                smooth={true}
                duration={1000}
                className="hover:opacity-50 transition-opacity"
                href="/#quero+"
              >
                Quero +
              </Link>
            </li>

            <li className="px-4">
              <Link
                activeClass="active"
                to="duvidas"
                spy={true}
                offset={-90}
                smooth={true}
                duration={1200}
                className="hover:opacity-50 transition-opacity"
                href="/#duvidas"
              >
                Dúvidas
              </Link>
            </li>
          </ul>
        </section>
      </nav>
    </header>
  )
}