'use client'

import { BeyondTreatmentOption } from "../components/BeyondTreatmentOption"
import PodcastImage from '../images/podcastImage.png'
import EbookImage1 from '../images/ebookImage.png'
import EbookImage2 from '../images/ebookImage2.png'
import MentoriaGrupo from '../images/mentoriaEmGrupo.png'
import ComunidadeImage from '../images/comunidadeImage.png'
import WhatsGroupImage from '../images/whatsGroup.png'
import Pri60DiasImage from '../images/PRI60Dias.png'

import SpotifyBackground from '../images/SpotifyLogo.svg'
import WhatsBackground from '../images/WhatsappLogo.svg'
import EbookBackground from '../images/BookBookmark.svg'
import ComunidadeBackground from '../images/UsersThree.svg'
import VideoBackground from '../images/VideoLogo.svg'
import SeloGarantia from '../images/seloGarantia.svg'

import clsx from "clsx"
import { Topic } from "../components/Topic"
import Button from "../components/Button"
import { Balancer } from "react-wrap-balancer"
import { Barbell, CheckFat, Dna, Heart, Laptop, PiggyBank, Question, UsersThree, Virus } from "@phosphor-icons/react"
import FAQComunidade from "./FAQComunidade"

const BeyondTreatment = () => {
  return (
    <>
    <section id="quero+" className={clsx("flex flex-col justify-center px-6 lg:px-28")}>
      <div className="flex flex-col">
        <h1 className="text-3xl md:text-5xl font-alt mb-16">Para além do tratamento</h1>


      </div>
    </section>

    <section className="mb-16 lg:mb-32">
        <BeyondTreatmentOption 
          title="Podcast" 
          subtitle="Somos 10% humanos" 
          description={
            <p>
            Oriento você a conquistar a saúde que você DESEJA para ter a vida que você MERECE através da Modulação intestinal.
            </p>
          }
          sourceImage={PodcastImage} 
          widthImage={280}
          buttonText="Ouça no Spotify"
          buttonLink="https://podcasters.spotify.com/pod/show/somosmicrobioma"
          bgImage={SpotifyBackground}
        />

        {/* <BeyondTreatmentOption 
          title="E-Book" 
          subtitle="Reprograme seu Intestino" 
          description={
            <p>
              São 60 páginas de conteúdo para você usar como guia no dia a dia e acabar de vez com os problemas do intestino de forma natural. Você não precisa imprimir, basta baixar no seu celular e consultar sempre que quiser.
            </p>
          }
          sourceImage={EbookImage1} 
          widthImage={280}
          buttonText="Comprar E-Book"
          buttonLink="https://chk.eduzz.com/1568386"
          bgImage={EbookBackground}
        /> */}

        <BeyondTreatmentOption 
          title="Mentoria em Grupo" 
          subtitle="Reprograme seu Intestino" 
          description={
            <p>
              <div className="flex-col text-base flex items-start justify-center gap-y-2">
                <div className="flex flex-row items-center justify-center gap-x-2">
                  <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                  <p className="flex flex-row gap-x-2 items-center">6 encontros de 2 horas via Zoom</p>
                </div>

                <div className="flex flex-row items-center justify-center gap-x-2">
                  <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                  <p className="flex flex-row gap-x-2 items-center">Estratégia de Modulação Intestinal, Detox de Fígado e Desparasitação</p>
                </div>

                <div className="flex flex-row items-center justify-center gap-x-2">
                  <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                  <p className="flex flex-row gap-x-2 items-center">Suplementação Individualizada</p>
                </div>

                <div className="flex flex-row items-center justify-center gap-x-2">
                  <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                  <p className="flex flex-row gap-x-2 items-center">Grupo aberto no Telegram para trocas entre os participantes</p>
                </div>
              </div>
            </p>
          }
          sourceImage={MentoriaGrupo} 
          buttonText="Entrar na Mentoria"
          buttonLink="https://chk.eduzz.com/2207673?utm_source=orbt-vst-vVxUMS4JI-gllYZxmbAh"
          widthImage={390}
          bgImage={ComunidadeBackground}
        />


        <BeyondTreatmentOption 
          title="Comunidade" 
          subtitle="Nutritudes" 
          description={
            <p>
              Seja você também uma Nutritude. Faça parte da comunidade para ter acesso a diversos conteúdos extras e ofertas EXCLUSIVAS.

              <div className="flex-col text-base flex items-start justify-center gap-y-2 pl-6 mt-8">
                <div className="flex flex-row items-center justify-center gap-x-2">
                  <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                  <p className="flex flex-row gap-x-2 items-center">Áudios curtos diariamente com o objetivo de manter sua motivação e foco ao longo do ano</p>
                </div>

                <div className="flex flex-row items-center justify-center gap-x-2">
                  <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                  <p className="flex flex-row gap-x-2 items-center">Todo mês um material em PDF (E-book ou Guia ou Infográfico)</p>
                </div>

                <div className="flex flex-row items-center justify-center gap-x-2">
                  <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                  <p className="flex flex-row gap-x-2 items-center">Master Classe mensal no Zoom (2 horas com você para trocar informações, dúvidas e conteúdos importantes para a SAÚDE DA MULHER)</p>
                </div>

                <div className="flex flex-row items-center justify-center gap-x-2">
                  <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                  <p className="flex flex-row gap-x-2 items-center">Comunidade aberta no Telegram para interação entre as participantes</p>
                </div>
              </div>
            </p>
          }
          sourceImage={ComunidadeImage} 
          widthImage={390}
          bgImage={ComunidadeBackground}
        />

        <div className="flex flex-col">
          <div className="mb-12">
            <div className="w-full lg:w-1/2 z-0 bg-gradient-to-r from-[#aeaead] h-12 flex flex-row items-center mb-4 px-6 lg:pl-28 gap-x-2">
              <h4 className="text-primary-600 text-base md:text-lg uppercase">Temas que serão abordados ao longo do ano na Comunidade</h4>
            </div>

            <div className="flex-col text-base flex items-start justify-center gap-y-4 px-6 lg:pl-28">
              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><Dna size={24} weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Exames</p>
              </div>

              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><Virus size={24} weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Modulação Intestinal</p>
              </div>

              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><Barbell size={24} weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Estilo de vida saudável (hábitos)</p>
              </div>

              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><Heart size={24} weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Influência do meio em nossa saúde</p>
              </div>
            </div>
          </div>

          <div className="mb-12">
            <div className="w-full lg:w-1/2 z-0 bg-gradient-to-r from-[#aeaead] h-12 flex flex-row items-center mb-4 px-6 lg:pl-28 gap-x-2">
              <h4 className="text-primary-600 text-base md:text-lg uppercase">Motivos Para Você Não Deixar Essa Oportunidade Passar</h4>
            </div>

            <div className="flex-col text-base flex items-start justify-center gap-y-4 px-6 lg:pl-28">
              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><Laptop size={24} weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Acesso a nutri via Zoom mensalmente</p>
              </div>

              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><UsersThree size={24} weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Apoio de um grupo seleto com os mesmos objetivos que você no Telegram</p>
              </div>

              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><Question size={24} weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Poderá tirar suas dúvidas em relação a sua saúde e alimentação ao vivo</p>
              </div>

              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><PiggyBank size={24} weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Terá descontos exclusivos em todo serviço ou produto oferecido pela nutri</p>
              </div>
            </div>
          </div>

          <div className="mb-12">
            <div className="w-full lg:w-1/2 z-0 bg-gradient-to-r from-[#aa8780] h-12 flex flex-row items-center mb-4 px-6 lg:pl-28 gap-x-2">
              <h4 className="text-brown-600 text-base md:text-lg uppercase">Para quem é esse grupo SELETO de mulheres</h4>
            </div>

            <div className="flex-col text-base flex items-start justify-center gap-y-4 px-6 lg:pl-28">
              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Para você que não foi paciente me conhecer um pouco melhor</p>
              </div>

              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Para você que já foi paciente, já chegou aonde queria e deseja manter contato para se manter onde chegou</p>
              </div>

              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Para você que precisa de consulta e não tem condições no momento, esta é uma alternativa para melhorar sua saúde</p>
              </div>

              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Para você que deseja criar laços com pessoas com o mesmo objetivo que você</p>
              </div>
            </div>
          </div>

          <div className="w-full flex items-center justify-center mt-12">
            <Button backgroundcolor={"brown-500"}>
              <a href="https://chk.eduzz.com/2207784?utm_source=orbt-vst-vVxUMS4JI-gllYZxmbAh" target="_blank" rel="noreferrer">
                Entrar na Comunidade
              </a> 
            </Button>
          </div>

          <div className="w-full bg-primary-500 h-64 md:h-[148px] flex flex-row mt-24 items-center px-6 gap-x-8 lg:px-28 mb-24">
            <div className="flex w-[200px]">
              <img src={SeloGarantia} alt="Kiwi" placeholder="blur" />
            </div>

            <div className="w-full flex flex-col items-center justify-between md:flex-row gap-y-8">
              <p className="flex text-center text-lg md:px-8 md:text-xl font-light text-background-color">
                Se por qualquer razão você achar que o investimento não valeu a pena, basta entrar em contato com a gente e você receberá de volta 100% do valor pago, sem burocracia
              </p>
            </div>
          </div>

          <FAQComunidade />
        </div>

        <BeyondTreatmentOption 
          title="Curso" 
          subtitle="PRI60DIAS" 
          description={
            <p>
              O Programa Reprogramando seu Intestino em 60 Dias (PRI60Dias) é sobre como ter uma vida incrível após os 40 anos através da modulação intestinal.
            </p>
          }
          sourceImage={Pri60DiasImage} 
          widthImage={300}
          bgImage={VideoBackground}
        />

        <div className="flex flex-col md:flex-row">
          <div className="w-full bg-gradient-to-r from-[#696B5C] h-12 flex md:hidden flex-row items-center mb-8">
            <h4 className="text-primary-600 text-sm sm:text-base uppercase px-8">Neste programa vou te mostrar:</h4>
          </div>

          <div className="w-full md:w-1/2 px-6 lg:px-28">
            <Topic index={1} text="Como recuperar a saúde, energia e disposição"/>
            <Topic index={2} text="Porque o seu corpo não respondeu as tentativas anteriores de dietas, atividade física e outras estratégias que você possa ter usado"/>
            <Topic index={3} text="Como conhecer os alimentos e como eles agem no seu corpo"/>
            <Topic index={4} text="Como ter articulações e músculos mais firmes e fortes mesmo após os 40 anos"/>

            <div className="hidden md:flex items-center justify-center mt-16">
              <a href="https://chat.whatsapp.com/LOmsSShoYC5HfwctK8zdYb" target="_blank" rel="noreferrer"><Button>Entrar na Lista de Espera do Curso</Button></a>
            </div>
          </div>

          <div className="w-full md:w-1/2 px-6 lg:px-28">
            <Topic index={5} text="Como ter um sono regenerador"/>
            <Topic index={6} text="Como regular as mudanças que seu corpo apresenta ao longo dos anos"/>
            <Topic index={7} text="Como iniciar um repertório de receitas práticas, rápidas e testadas pra você que não tem muito tempo"/>

            <div className="md:hidden flex items-center justify-center my-16">
              <a href="https://chat.whatsapp.com/LOmsSShoYC5HfwctK8zdYb" target="_blank" rel="noreferrer"><Button>Entrar na Lista de Espera do Curso</Button></a>
            </div>

            <div className="flex flex-col">
              <div className="mb-8">
                <h1 className="text-xl md:text-2xl uppercase mb-2">O que o programa contempla</h1>

                <p>
                  <Balancer>
                    Quatro aulas ao vivo pelo Zoom e acesso ao Grupo do WhatsApp pra receber o link das aulas e comunicados importantes. 
                  </Balancer>
                </p>
              </div>

              <div>
                <h1 className="text-xl md:text-2xl uppercase mb-2">O que o programa não contempla</h1>

                <p>
                  <Balancer>
                  Prescrição personalizada da nutricionista. Isso você irá obter somente nos atendimentos INDIVIDUALIZADOS online ou em consultório.
                  </Balancer>
                </p>
              </div>

            </div>
          </div>
        </div>


    </section>
    </>
  )
}
export default BeyondTreatment