import clsx from "clsx"

import NutriEliete from '../images/nutriElieteAbout.png'
import CircleBackground from '../images/CircleBackground.svg'
import { CheckFat } from "@phosphor-icons/react"
import { Balancer } from "react-wrap-balancer"

const About = () => {
  return (
    <main id="sobre" className={clsx("min-h-screen flex flex-1 flex-col justify-center px-6 lg:px-28 mb-16 lg:mb-32")}>
      <div className="flex flex-col">
        <h1 className="text-3xl md:text-5xl font-alt mb-8"><Balancer>Conheça um pouco mais da sua nutri Eliete Borges</Balancer></h1>

        <div className="flex flex-row">
          <div className="w-full">
            <div className="relative float-right flex flex-col ml-8">
              <img className="absolute top-40 right-20 -z-10" alt="" src={CircleBackground} width={300}/>
              <img className="w-[150px] sm:w-[200px] md:w-[350px]" src={NutriEliete} alt="" width={350}/>
              
              <div className="text-sm md:text-base flex flex-col items-end justify-end">
                <span>Eliete Borges</span>
                <span>CRN-SC 3152</span>
              </div>
            </div>

            <p>
              Sou Nutricionista Funcional expert em Modulação Intestinal e atendo de forma 100% online ajudando mulheres de todo o Brasil e exterior.
              <br /><br />
              Estou aqui para te ajudar a regular as mudanças em seu corpo e te ensinar a:
              <br /><br />
            </p>

            <div className="flex-col text-base flex items-start justify-center gap-y-2 pl-6">

              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Controlar Incômodos Gastrointestinais</p>
              </div>

              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Remissão de Doenças Crônicas/Inflamatórias</p>
              </div>

              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Controlar o Ganho de Peso</p>
              </div>

              <div className="flex flex-row items-center justify-center gap-x-2">
                <span><CheckFat size={24} color="#763B2E" weight="duotone" /></span>
                <p className="flex flex-row gap-x-2 items-center">Aumentar sua Energia</p>
              </div>
            </div>

            <p>
              <br />
              <Balancer>
              Durante todo esse processo eu serei o seu braço direito, aquela pessoa que não vai te julgar e sim te ajudar a encontrar soluções para as suas dificuldades.
    
              <br /><br />
          
              Com os meus anos de experiência e muito estudo, desenvolvi um método para te ajudar nessa jornada e fazer com que ela não seja um sofrimento.
             
              <br /><br />
             
              Sou uma apaixonada por atividades ao ar livre como trekking, pedal, caiaque, rafting. Sempre gostei de esporte e um estilo de vida saudável, que me proporcionasse autonomia física e mental ao longo da minha vida.
             
              <br />< br/>
             
              Por isso me especializei em Modulação Intestinal, órgão que acredito ser a porta de todas as doenças, mas também sua cura.
              </Balancer>
            </p>
          </div>
        </div>
      </div>
    </main>
  )
}
export default About