import { ComponentProps, forwardRef } from 'react'
import clsx from 'clsx'

type ButtonProps = Omit<ComponentProps<'button'>, 'className'> & {
  active?: boolean
  withouthBg?: boolean
  backgroundcolor?: 'blue-500' | 'brownkiwi-500' | 'brown-500' | 'primary'
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, active = false, ...props }, ref) => {
    
    var bgColor = 'bg-primary-500'
    var bgColorHover = 'hover:bg-primary-700'

    if(props.backgroundcolor === 'blue-500') {
      bgColor = 'bg-blue-500'
      bgColorHover = 'hover:bg-blue-600'
    } else if (props.backgroundcolor === 'brownkiwi-500') {
      bgColor = 'bg-brownkiwi-500'
      bgColorHover = 'hover:bg-brownkiwi-600'
    } else if (props.backgroundcolor === 'brown-500') {
      bgColor = 'bg-brown-500'
      bgColorHover = 'hover:bg-brown-600'
    }
    

    return(
    <button
      className={clsx(
        active
          ? 'bg-primary-600'
          : props.withouthBg
          ? 'bg-transparent text-primary-600 ring-2 ring-inset ring-beige-500 hover:bg-primary-600 hover:text-background-color'
          : `${bgColor} ${bgColorHover}`,
        'transition w-max duration-500 focus:outline-none ring-4 ring-beige-500 focus-visible:ring focus-visible:ring-beige-500 focus-visible:ring-opacity-75',
        'px-3 py-2 text-center text-sm text-background-color lg:text-base uppercase font-normal tracking-wide',
      )}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  )},
)

Button.displayName = 'Button'
export default Button