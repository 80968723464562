import clsx from "clsx"

import TestimonyImage from '../images/testimony.png'
import { CaretLeft, CaretRight } from "@phosphor-icons/react"
import { Balancer } from "react-wrap-balancer"
import { useEffect, useState } from "react"

const testimonies = [
  {
    author: "Fabiana",
    testimony: (
      <span>
        Quando a Dra Eliete me sugeriu
fazer o programa de Modulação
Intestinal, confesso que fiquei um
pouco apreensiva com esta
sugestão, pois não sabia se eu
conseguiria seguir a dieta
sugerida.
<br /><br />
Mas, tive uma surpresa incrível...
durante o processo observei que
as questões que me
incomodavam (sensação de
estômago inchado, constipação,
má digestão, etc) simplesmente
desapareceram.
<br /><br />
E, a cereja do bolo foi a perda de
5 kg em 2 meses de dieta.
Confesso, que a princípio achei
que não conseguiria cumprir o
proposto, mas depois que senti
os resultados, toda restrição não
teve mais importância!
Com certeza, valeu muito a pena
ter feito a modulação intestinal.
<br /><br />
Obrigada Dra Eliete por ter me
proporcionado isso. Foi um
Sucesso!!
      </span>
    ),
  },
  {
    author: "Silvana",
    testimony: (
      <span>
        Achei o atendimento excelente. Me senti super a vontade e acolhida para expor meus problemas e esclarecer minhas dúvidas.
        <br /><br />
        Gostei das sugestões de cardápio. Pontualidade, muito conhecimento, interesse e acolhimento.
      </span>
    ),
  },
  {
    author: 'Renan',
    testimony: (
      <span>
        A metodologia de trabalho da Dra. Eliete é fenomenal. Com muita organização, materiais de qualidade, fácil comunicação e conteúdo digital acessível em qualquer momento, ela proporciona praticidade e leveza a uma dieta em que muitas vezes pode ser difícil para algumas pessoas.
        <br /><br />
        Após os meus relatos, ela buscou explicar cada particularidade dos meus exames na essência, em seu mínimo detalhe. Como sou vegetariano, já havia passado por vários profissionais que não conseguiram resolver a minha situação. A Dra. literalmente me virou do avesso!
        <br /><br />
        Muito obrigado Dra.!
      </span>
    ),
  },
  {
    author: 'Denice',
    testimony: (
      <span>
        Seu atendimento é muito bom, você explica tudo super bem e escutou minhas preocupações e eu gostei muito disso. Responde as perguntas no whats.
        <br /><br />
        Escuta a paciente e explica tudo muito bem. Você não está com pressa o que é bom. Nota 10, eu indico por que achei o atendimento muito bom!
      </span>
    ),
  },
  {
    author: 'Karikris',
    testimony: (
      <span>
        Para retocolite ulcerativa o remédio nunca fez efeito, o meu médico me orientou a trocar o remédio, mas antes de trocar eu decidi fazer a modulação intestinal com você e está sendo incrível, com 20 dias seguindo o seu e-book já comecei a ver resultados.
        <br /><br />
        A minha dor abdominal sumiu, diarréia e sangramento também. Durante esses 2 meses eu perdi 10kg e o meu marido que está me ajudando na dieta também emagreceu 10kg, além disso, ele tinha pressão alta e conseguiu regular a pressão.
        <br /><br />
        Passando aqui pra te dizer que o seu trabalho é muito importante na minha vida. Agradecer a Deus que através da minha mãe falou para eu seguir seu Instagram.
      </span>
    ),
  },
]

const Testimonies = () => {
  const[testimonyActive, setTestimonyActive] = useState(testimonies[0])
  const[firstTestimonyActive, setFirstTestimonyActive] = useState(false)
  const[lastTestimonyActive, setLastTestimonyActive] = useState(false)

  function handleNextTestimony() {
    const indexOfActualTestimony = testimonies.findIndex(item => item.testimony === testimonyActive.testimony);

    const qtdTestimonies = testimonies.length
    const indexOfNextTestimony = indexOfActualTestimony + 1

    if(indexOfNextTestimony <= qtdTestimonies - 1) {
      setTestimonyActive(testimonies[indexOfNextTestimony]);
    } else {
      setTestimonyActive(testimonies[indexOfActualTestimony]);
    }
  }

  function handlePreviousTestimony() {
    const indexOfActualTestimony = testimonies.findIndex(item => item.testimony === testimonyActive.testimony);

    const indexOfPreviousTestimony = indexOfActualTestimony - 1

    if(indexOfPreviousTestimony >= 0) {
      setTestimonyActive(testimonies[indexOfPreviousTestimony]);
    } else {
      setTestimonyActive(testimonies[indexOfActualTestimony]);
    }
  }

  useEffect(() => {
    const firstTestimony = testimonies[0].testimony
    const lastTestimony = testimonies[testimonies.length - 1].testimony

    if(lastTestimony === testimonyActive.testimony) {
      setLastTestimonyActive(true)
      setFirstTestimonyActive(false)
    }

    if(firstTestimony === testimonyActive.testimony) {
      setFirstTestimonyActive(true)
      setLastTestimonyActive(false)
    } 
  }, [testimonyActive])

  return (
    <main className={clsx("min-h-screen flex flex-1 flex-col justify-center mb-16 lg:mb-32")}>
      <div className="flex flex-col">
        <h1 className="w-full text-3xl md:text-5xl font-alt mb-8 px-6 lg:px-28"><Balancer>O que estão falando sobre o acompanhamento?</Balancer></h1>

        <div className="flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-1/2 px-6 lg:pl-28 flex items-center justify-center">
            <div className="w-[300px] md:w-[350px] lg:w-[400px]">
              <img src={TestimonyImage} alt='Mulher comendo salada de frutas' width={400} placeholder="blur" />
            </div>
          </div>

          {/* <div className='w-[80%] md:w-[40%] absolute right-0 flex flex-col mt-[80%] md:mt-8'> */}
          <div className='w-full md:w-[80%] lg:w-[40%] flex flex-col md:mt-8 pl-6 md:pl-0'>
            <div className="w-full bg-primary-500 h-14 rounded-tl-lg flex items-center justify-between pl-8 mb-8">
              <h3 className="text-background-color w-[10%]">{testimonyActive.author}</h3>
              
              <div className="mr-16 mb-16 flex flex-row items-center justify-center gap-4">

                {
                  (testimonyActive.testimony === testimonies[testimonies.length - 1].testimony) || (testimonyActive.testimony === testimonies[0].testimony) || lastTestimonyActive && (testimonyActive.testimony !== testimonies[0].testimony) && (testimonyActive.testimony !== testimonies[testimonies.length - 1].testimony) ? <div className="rounded-full h-11 w-11"></div> :
                  <div className="cursor-pointer rounded-full border-[3px] border-primary-500 bg-background-color h-11 w-11 flex items-center justify-center"
                    onClick={handlePreviousTestimony}
                  >
                    <span className="text-primary-600"><CaretLeft size={24} weight="fill" /></span>
                  </div>
                }


                { lastTestimonyActive ? 
                  <div className="cursor-pointer rounded-full border-[3px] border-primary-500 bg-background-color h-24 w-24 flex items-center justify-center"
                  onClick={handlePreviousTestimony}
                >
                  <div className="rounded-full border-[1px] border-primary-500 bg-transparent h-20 w-20 flex items-center justify-center">
                    <span className="select-none text-primary-600">
                      Anterior
                    </span>
                  </div>
                </div>
                :
                <div className="cursor-pointer rounded-full border-[3px] border-primary-500 bg-background-color h-24 w-24 flex items-center justify-center"
                  onClick={handleNextTestimony}
                >
                  <div className="rounded-full border-[1px] border-primary-500 bg-transparent h-20 w-20 flex items-center justify-center">
                    <span className="select-none text-primary-600">
                      Próximo
                    </span>
                  </div>
                </div>
              }

              {
                (testimonyActive.testimony === testimonies[testimonies.length - 1].testimony) || (testimonyActive.testimony === testimonies[0].testimony) || firstTestimonyActive && (testimonyActive.testimony !== testimonies[0].testimony) && (testimonyActive.testimony !== testimonies[testimonies.length - 1].testimony) ? <div className="rounded-full h-11 w-11"></div> :
                <div className="cursor-pointer rounded-full border-[3px] border-primary-500 bg-background-color h-11 w-11 flex items-center justify-center"
                  onClick={handleNextTestimony}
                >
                  <span className="text-primary-600"><CaretRight size={24} weight="fill" /></span>
                </div>
              }
              </div>
            </div>


              <p className="pr-6 lg:pr-28">
                {testimonyActive.testimony}
              </p>

          </div>

        </div>
      </div>
    </main>
  )
}
export default Testimonies