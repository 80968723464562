import NutriCapa from '../images/nutriCapa.png'
import SemiCircunference from '../images/SemiCircunference.svg'
import SemiCircunferenceWhite from '../images/SemiCircunferenceWhite.svg'
import { useEffect, useRef } from 'react'

import { AnimatePresence, motion } from 'framer-motion'

import clsx from 'clsx'

import VerdurasLegumes1 from '../images/verdurasLegumes1.png'
import VerdurasLegumes2 from '../images/verdurasLegumes2.png'
import Polifenois1 from '../images/polifenois1.png'
import Polifenois2 from '../images/polifenois2.png'
import FibrasAgua2 from '../images/fibrasAgua2.png'

import { useFood } from '../context/foods.context'
import Button from '../components/Button'
import { ButtonUp } from '../components/ButtonUp'



const Main = () => {
  const{food, handleChangeFood} = useFood()
  const textRef = useRef<HTMLDivElement>(null)

  function handleFoodToDefault() {
    handleChangeFood('none')
  }



  useEffect(() => {
    const currentDescriptionRef = textRef.current

    function handleClickOutside(event: MouseEvent) {
      if (
        currentDescriptionRef &&
        !currentDescriptionRef.contains(event.target as Node)
      ) {
        console.log('eita')
        console.log(event.target)
        console.log(currentDescriptionRef)
        handleFoodToDefault()
      }
    }

    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        handleFoodToDefault()
      }
    }

    if (
      (food.food !== "none") &&
      currentDescriptionRef
    ) {
      document.addEventListener('click', handleClickOutside)
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      if (
        (food.food !== 'none') &&
        currentDescriptionRef
      ) {
        document.removeEventListener('click', handleClickOutside)
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [food])

  return (
    <main className={clsx("-h-[calc(100vh - 136px)] relative flex flex-1 flex-col items-center justify-center pt-[100px] lg:pt-0 lg:flex-row px-0 lg:px-28",
    food?.food === 'Frutas e Verduras' ? "bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))]  from-[#327839] via-[#327839] to-[#44525A]" : "bg-background-color",
    food?.food === 'Polifenóis' ? "bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))]  from-[#784032] via-[#784032] to-[#5A5344]" : "bg-background-color",
    food?.food === 'Água' ? "bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))]  from-[#325B78] via-[#325B78] to-[#5A5A44]" : "bg-background-color",
    )}
    
    >
      <ButtonUp />
      {
        food?.food === "none" ? 
        <div className="lg:absolute w-full flex items-center justify-center h-[116px] bg-primary-500 top-44">
          <AnimatePresence mode="wait">
            <motion.div
              key={0}
              initial={{ y: 0, opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ y: 0, opacity: 0 }}
              transition={{ duration: 0.5 }}
              className='flex flex-row items-center justify-center'
            >
              {/* <div className='absolute left-0 z-10 ml-48 flex w-[232px] h-[232px] border-[3px] border-primary-500 rounded-full'> */}
              <div className='absolute -top-[84px] z-10 ml-48 left-0 hidden xl:flex'>
                <img src={NutriCapa} alt='Foto da Nutricionista Eliete Rodrigues sorrindo, cabelo com cachos preto, pele clara, olhos de cor preta, camisa branca.' width={250}
                  placeholder="blur"
                />
              </div>

              {/* <div className='absolute left-0 z-0 ml-[200px] flex w-[216px] h-[216px] border-2 border-background-color rounded-full opacity-50'></div> */}

              {/* <div className='absolute ml-48 flex w-[256px] h-[256px] border-4 border-background-color rounded-full left-0'>

              </div> */}

              <div className='w-fit md:w-96'>
                <span className="flex text-background-color font-alt text-2xl lg:text-[28px]">
                    Nutricionista Funcional Especializada
                </span>

                <span className='flex items-center justify-center text-background-color font-alt text-2xl lg:text-[28px]'>
                  em Modulação Intestinal
                </span>
              </div>



            </motion.div>
          </AnimatePresence>
        </div>
        :
        <></>
      }

      {
        food?.food !== "none" ? 
        <div className="lg:absolute w-full flex items-center justify-center h-32 top-44">
        <AnimatePresence mode="wait">
          <motion.div
            key={0}
            initial={{ y: 0, opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ y: 0, opacity: 0 }}
            transition={{ duration: 0.5 }}
            className='flex flex-row items-center justify-center'
          >
            <div className='z-20 w-fit md:w-96'>
              <span className="flex text-background-color font-alt text-2xl lg:text-[28px]">
                  Alimentos de um
              </span>

              <span className='flex items-center justify-center text-background-color font-alt text-2xl lg:text-[28px]'>
                Intestino Saudável
              </span>
            </div>
          </motion.div>
        </AnimatePresence>
        </div>
      :<></>
      }
      
      {/* <div className="absolute top-[40%] flex w-[934px] h-[934px] border-4 border-primary-500 rounded-full">

      </div> */}

      {/* Textos do Semicirculo  DESKTOP*/}
      <div className='hidden z-30 absolute items-center justify-center bottom-0 lg:flex'>
        <svg width="775" height="430" viewBox="0 0 775 382" xmlns="http://www.w3.org/2000/svg">
          <path id='curve' fill="transparent" d="M772.189 381.162C772.19 380.942 772.19 380.721 772.19 380.5C772.19 171.493 599.81 2 387.095 2C174.38 2 2 171.493 2 380.5C2 380.721 2.00019 380.942 2.00058 381.162H0.000573454C0.000191203 380.942 0 380.721 0 380.5C0 170.356 173.308 0 387.095 0C600.882 0 774.19 170.356 774.19 380.5C774.19 380.721 774.19 380.942 774.189 381.162H772.189Z"/>
            {food?.food === "Frutas e Verduras" ? <circle cx="390" cy="25" r="8" fill="#A6A5A5" stroke="white" strokeWidth="2"/> : <></>}
            {food?.food === "Polifenóis" ? <circle cx="550" cy="60" r="8" fill="#A6A5A5" stroke="white" strokeWidth="2"/> : <></>}
            {food?.food === "Água" ? <circle cx="230" cy="57" r="8" fill="#A6A5A5" stroke="white" strokeWidth="2"/> : <></>}

            <a className='HIDDEN-HEIGHT cursor-pointer text-white hover:opacity-50 transition-opacity' onClick={() => handleChangeFood("Água")}>
              <text x='410' fill={food?.food !== 'none' ? 'white' : '#2E3213'}>
                <textPath xlinkHref="#curve" textAnchor="start" startOffset="50%">
                  {food?.food === "Água" ? <tspan dy="0">Água</tspan> : <tspan dy="-10">Água</tspan>}
                  {food?.food === "Água" ? <></> : <tspan dx="-47" dy="20" className='text-sm'>saiba mais</tspan>}
                </textPath>
              </text>
            </a>

            <a className='HIDDEN-HEIGHT cursor-pointer hover:opacity-50 transition-opacity' onClick={() => handleChangeFood("Frutas e Verduras")}>
              <text x='540' fill={food?.food !== 'none' ? 'white' : '#2E3213'}>
                <textPath xlinkHref="#curve" textAnchor="start" startOffset="50%">
                  {food?.food === "Frutas e Verduras" ? <tspan dx="8" dy="0">Frutas e Verduras</tspan> : <tspan dx="8" dy="-10">Frutas e Verduras</tspan>}
                  {food?.food === "Frutas e Verduras" ? <></> : <tspan dx="-92" dy="20" className='text-sm'>saiba mais</tspan>}
                </textPath>
              </text>
            </a>

            <a className='HIDDEN-HEIGHT cursor-pointer hover:opacity-50 transition-opacity' onClick={() => handleChangeFood("Polifenóis")}>
              <text x='750' fill={food?.food !== 'none' ? 'white' : '#2E3213'}>
                <textPath xlinkHref="#curve" textAnchor="start" startOffset="50%">
                  {food?.food === "Polifenóis" ? <tspan dy="0">Polifenóis</tspan> : <tspan dy="-10">Polifenóis</tspan>}
                  {food?.food === "Polifenóis" ? <></> : <tspan dx="-65" dy="20" className='text-sm'>saiba mais</tspan>}
                </textPath>
              </text>
            </a>
        </svg>
      </div>


      {/* MOBILE */}
      <div className='lg:hidden w-full flex flex-col mt-8'>
        <div className='z-20 lg:hidden flex flex-row items-center justify-center gap-x-12 mb-2'>
          <a className={`cursor-pointer ${food?.food === 'none' ? 'text-primary-500' : 'text-white'} hover:opacity-50 transition-opacity`} onClick={() => handleChangeFood("Água")}>
            <div className='flex flex-col items-center justify-center'>
              <span>Água</span>
              {food?.food === "Água" ? <></> : <span className='text-sm'>saiba mais</span>}
            </div>
          </a>
          <a className={`cursor-pointer ${food?.food === 'none' ? 'text-primary-500' : 'text-white'} hover:opacity-50 transition-opacity`} onClick={() => handleChangeFood("Frutas e Verduras")}>
            <div className='flex flex-col items-center justify-center'>
              <span>Frutas e Verduras</span>
              {food?.food === "Frutas e Verduras" ? <></> : <span className='text-sm'>saiba mais</span>}
            </div>
          </a>
          <a className={`cursor-pointer ${food?.food === 'none' ? 'text-primary-500' : 'text-white'} hover:opacity-50 transition-opacity`} onClick={() => handleChangeFood("Polifenóis")}>
            <div className='flex flex-col items-center justify-center'>
              <span>Polifenóis</span>
              {food?.food === "Polifenóis" ? <></> : <span className='text-sm'>saiba mais</span>}
            </div>
          </a>
        </div>

        <hr className={`border-[1.5px] ${food?.food === 'none' ? 'border-primary-500' : 'border-white'} mb-6`}></hr>
        <hr className={`border-1 ${food?.food === 'none' ? 'border-primary-500' : 'border-white'}`}></hr>

        <div className='lg:hidden flex flex-col items-center justify-center'>
          <div className='z-40 h-2/3 gap-y-4 flex flex-col text-center items-center justify-center text-base font-light mt-8 px-8 md:px-44'>
            {food?.text}

            <div className='h-1/3 flex items-center'>
              <Button backgroundcolor={food?.food === 'Água' ? 'blue-500' : food?.food === 'Polifenóis' ? 'brown-500' : 'primary'}>
                <a href="https://api.whatsapp.com/send?phone=5547999785847&text=Ol%C3%A1!%20Encontrei%20voc%C3%AA%20pelo%20site" target='_blank' rel="noreferrer">Fale Comigo</a>
              </Button>
            </div>

          </div>
        </div>
      </div>

      {/* DESKTOP */}
      <div className='hidden absolute lg:flex flex-col items-center justify-center bottom-32 lg:bottom-0'>
        <img className='HIDDEN-HEIGHT' src={food?.food === "none" ? SemiCircunference : SemiCircunferenceWhite} alt=''/>

        <div className='HIDDEN-HEIGHT z-40 h-2/3 gap-y-4 lg:absolute flex flex-col text-center items-center justify-center w-[500px] text-base font-light mt-16 px-8'>
          {food?.text}

          <div className='HIDDEN-HEIGHT h-1/3 flex items-center'>
            <Button backgroundcolor={food?.food === 'Água' ? 'blue-500' : food?.food === 'Polifenóis' ? 'brown-500' : 'primary'}>
              <a href="https://api.whatsapp.com/send?phone=5547999785847&text=Ol%C3%A1!%20Encontrei%20voc%C3%AA%20pelo%20site" target='_blank' rel="noreferrer">Fale Comigo</a>
            </Button>
          </div>

        </div>
      </div>

      <div>
      {food?.food === "Frutas e Verduras" ?
        <AnimatePresence mode="wait">
          <motion.div
            key={food?.food}
            initial={{ y: 0, opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ y: 0, opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <div className='absolute top-6 md:top-24 lg:top-24 left-0 w-[150px] md:w-[250px] lg:w-[423px]'>
              <img src={VerdurasLegumes1} alt='' width={423}
                placeholder="blur"
              />
            </div>

            <div className='absolute bottom-0 right-0 w-[150px] md:w-[250px] lg:w-[420px]'>
              <img src={VerdurasLegumes2} alt='' width={420}
                placeholder="blur"
              />
            </div>
          </motion.div>
        </AnimatePresence>
        :
        <></>
      }

      {food?.food === "Polifenóis" ?
        <AnimatePresence mode="wait">
          <motion.div
            key={food?.food}
            initial={{ y: 0, opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ y: 0, opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <div className='absolute bottom-0 -left-56 lg:-left-24'>
              <img src={Polifenois1} alt='' width={338}
                placeholder="blur"
              />
            </div>

            <div className='absolute top-6 md:top-24 lg:top-6 right-0 w-[150px] md:w-[250px] lg:w-[380px]'>
              <img src={Polifenois2} alt='' width={380}
                placeholder="blur"
              />
            </div>
          </motion.div>
        </AnimatePresence>
        :
        <></>
      }

      {food?.food === "Água" ?
        <AnimatePresence mode="wait">
          <motion.div
            key={food?.food}
            initial={{ y: 0, opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ y: 0, opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <div className='z-30 absolute bottom-0 left-0'>
              <img src={FibrasAgua2} alt=''height={219}
                placeholder="blur"
              />
            </div>
          </motion.div>
        </AnimatePresence>
        :
        <></>
      }
      </div>
    </main>
  )
}

export default Main